import React from 'react';
import { Link } from 'gatsby';
import AuthContext from '../../contexts/AuthContext';
import TemplateWrapper from '../../layouts';
import {
  Heading,
  ButtonText,
  FlexButton,
} from '../../components/styled-components/elements';
import { FlexCollection } from '../../components/styled-components/structures';

class Members extends React.Component {
  render() {
    function generateMembersElement(codeInputted) {
      return codeInputted ? (
        <div>
          <Heading>
            {`Welcome to the members' area - please select 'Teach Tracks' for links or
            'Resources' for other updates and information`}
          </Heading>
          <FlexCollection>
            <Link to="members/teach-tracks">
              <FlexButton>
                <ButtonText>Teach Tracks</ButtonText>
              </FlexButton>
            </Link>
            <Link to="members/resources">
              <FlexButton>
                <ButtonText>Resources</ButtonText>
              </FlexButton>
            </Link>
          </FlexCollection>
        </div>
      ) : (
        <p>Please log in :)</p>
      );
    }
    return (
      <TemplateWrapper>
        <AuthContext.Consumer>
          {({ codeInputted }) => {
            const membersElement = generateMembersElement(codeInputted);
            return membersElement;
          }}
        </AuthContext.Consumer>
      </TemplateWrapper>
    );
  }
}

export default Members;
